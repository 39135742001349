<template>
    <div class="block">
        <small v-if="data.BottomText" :class="data.BottomTextClass">{{ data.BottomText }}</small>
        <b-field class="mb-0" :label="data.Label" v-if="data.Type === 'inputtext'">
            <b-input v-model="result" :type="data.FieldType" :name="data.FieldToSave" :placeholder="data.Placeholder"></b-input>
        </b-field>
    </div>
</template>
<script>
export default {
    data () {
        return {
            result: ''
        }
    },
    props: {
        data: {
            type: Object
        }
    },
    methods: {
        async setDefault () {
            var defaultValue = this.data.Default
            var value
            if (Array.isArray(defaultValue)) {
                defaultValue.forEach(item => {
                    if (!value) value = this[item]
                    else value = value[item]
                })
            } else if (typeof defaultValue === 'string') {
                if (defaultValue === 'timestamp') {
                    value = 'web_' + new Date().getTime()
                } else {
                    value = defaultValue
                }
            }
            this.result = value
        }
    },
    mounted () {
        this.setDefault()
    }
}
</script>
